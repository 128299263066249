import React from "react";
import { Filter, TextInput } from "react-admin";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";

const ShipmentFilter = props => (
  <Filter {...props}>
    <TextInput source="waybill" alwaysOn />
    <TextInput source="iata" alwaysOn />
    <TextInput source="facility" alwaysOn />
  </Filter>
);

const ShipmentList = props => (
  <ListGuesser
    {...props}
    filters={<ShipmentFilter />}
    bulkActionButtons={false}
    title="DHL Expéditions"
  >
    <FieldGuesser source={"waybill"} />
    <FieldGuesser source={"iata"} />
    <FieldGuesser source={"facility"} />
    <FieldGuesser source={"nbPieces"} />
    <FieldGuesser source={"pieceDoneCount"} sortable={false} />
    <FieldGuesser source={"pickupDate"} />
    <FieldGuesser source={"shipmentDate"} />
  </ListGuesser>
);

export { ShipmentList };
