import React from "react";
import { Resource } from "react-admin";
import { HydraAdmin, ResourceGuesser, ShowGuesser } from "@api-platform/admin";
import {
  People,
  CardMembership,
  CardTravel,
  DeviceHub,
  DynamicFeed
} from "@material-ui/icons";
import authProvider from "./app/authProvider";
import { IataList, IataCreate, IataEdit } from "./resource/iata";
import {
  FacilityList,
  FacilityCreate,
  FacilityEdit
} from "./resource/facility";
import { PieceList } from "./resource/piece";
import { ShipmentList } from "./resource/shipment";
import { RelaisColisParcelList } from "./resource/relais_colis_parcel";
import { RoleList } from "./resource/role";
import { UserList, UserCreate, UserEdit } from "./resource/user";
import Layout from "./app/Layout";
import LoginPage from "./app/LoginPage";
import theme from "./app/theme";
import customRoutes from "./app/customRoutes";
import { dataProvider, apiDocumentationParser } from "./app/dataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import frenchMessages from "ra-language-french";

const entrypoint = process.env.API_URL;
const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr");

export default () => (
  <HydraAdmin
    apiDocumentationParser={apiDocumentationParser}
    dataProvider={dataProvider}
    authProvider={authProvider(entrypoint)}
    entrypoint={entrypoint}
    theme={theme}
    layout={Layout}
    customRoutes={customRoutes}
    loginPage={LoginPage}
    i18nProvider={i18nProvider}
  >
    {permissions => [
      <Resource
        name="shipments"
        key="shipments"
        options={{ label: "DHL Expéditions" }}
        list={ShipmentList}
        show={ShowGuesser}
        icon={CardTravel}
      />,
      <Resource
        name="pieces"
        key="pieces"
        options={{ label: "DHL Colis" }}
        list={PieceList}
        show={ShowGuesser}
        icon={DynamicFeed}
      />,
      <Resource
        name="relais_colis_parcels"
        key="relais_colis_parcels"
        options={{ label: "Relais Colis" }}
        list={RelaisColisParcelList}
        show={ShowGuesser}
      />,
      permissions.includes("ROLE_ADMIN") && [
        <ResourceGuesser
          name="iatas"
          key="iatas"
          list={IataList}
          create={IataCreate}
          edit={IataEdit}
          icon={DeviceHub}
        />,
        <ResourceGuesser
          name="facilities"
          key="facilities"
          list={FacilityList}
          create={FacilityCreate}
          edit={FacilityEdit}
          icon={DeviceHub}
        />,
        <ResourceGuesser
          name="users"
          key="users"
          options={{ label: "Utilisateurs" }}
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          icon={People}
        />,
        <Resource
          name="roles"
          key="roles"
          options={{ label: "Rôles" }}
          list={RoleList}
          icon={CardMembership}
        />
      ]
    ]}
  </HydraAdmin>
);
