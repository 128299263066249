import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function PieceClickDialog({ open, handleClose, handleAccept }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Colis traité ?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"En cliquant sur « Traiter », vous confirmez que"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={handleAccept}
          color="primary"
          variant="contained"
          autoFocus
        >
          Traiter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PieceClickDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAccept: PropTypes.func
};
