import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import BarcodeImage from "../../components/BarcodeImage";

const useStyles = makeStyles({
  large: {
    fontSize: "255%"
  },
  largeTypography: {
    fontSize: "50%"
  },
  copyToClipboard: {
    cursor: "pointer"
  },
  pieceContainer: {
    textAlign: "center"
  }
});

const Piece = ({
  piece,
  nbPiece,
  nbPieceDone,
  waybill,
  iata,
  facility,
  handleClick
}) => {
  const classes = useStyles();
  const done = piece.status === "done";

  return (
    <Grid
      container
      direction={"column"}
      wrap={"wrap"}
      className={classes.pieceContainer}
    >
      <Grid item className={classes.large}>
        <Typography
          className={classes.largeTypography}
          variant="subtitle1"
          gutterBottom
        >
          Iata: <strong>{iata}</strong> / Facility: <strong>{facility}</strong>{" "}
          / Expédition:{" "}
          <strong
            className={classes.copyToClipboard}
            onClick={() => handleClick(waybill)}
          >
            {waybill}
          </strong>
        </Typography>
        <Typography
          className={classes.largeTypography}
          variant="subtitle2"
          gutterBottom
        >
          Colis traités: <strong>{nbPieceDone}</strong> /{" "}
          <strong>{nbPiece}</strong>
        </Typography>
        {done && (
          <Card style={{ width: "80%", margin: "0 10%" }}>
            <CardContent>
              Ce colis est <span style={{ color: "#22b422" }}>déjà traité</span>
              .
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item style={{ paddingTop: 24 }}>
        <Typography variant="h3" gutterBottom>
          WB:{" "}
          <code
            className={classes.copyToClipboard}
            onClick={() => handleClick(waybill)}
          >
            {waybill}
          </code>
        </Typography>
        <BarcodeImage value={waybill} onClick={() => handleClick(waybill)} />
      </Grid>
      <Grid item style={{ paddingTop: 24 }}>
        <Typography variant="h3" gutterBottom>
          SmartCode:{" "}
          <code
            className={classes.copyToClipboard}
            onClick={() => handleClick(piece.smartCode)}
          >
            {piece.smartCode}
          </code>
        </Typography>
        <BarcodeImage
          value={piece.smartCode}
          onClick={() => handleClick(piece.smartCode)}
        />
      </Grid>
      <Grid item style={{ paddingTop: 24 }}>
        <Typography variant="h3" gutterBottom>
          PID:{" "}
          <code
            className={classes.copyToClipboard}
            onClick={() => handleClick(piece.pid)}
          >
            {piece.pid}
          </code>
        </Typography>
        <BarcodeImage
          value={piece.pid}
          onClick={() => handleClick(piece.pid)}
        />
      </Grid>
    </Grid>
  );
};

Piece.propTypes = {
  piece: PropTypes.object,
  nbPiece: PropTypes.number,
  nbPieceDone: PropTypes.number,
  waybill: PropTypes.string,
  iata: PropTypes.string,
  facility: PropTypes.string,
  handleClick: PropTypes.func
};

export default Piece;
