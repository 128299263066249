import PropTypes from "prop-types";
import React from "react";

const QRCodeImage = ({ value, size, onClick }) => {
  if (onClick === undefined) {
    onClick = () => {};
  }

  return (
    <img
      onClick={onClick}
      src={process.env.API_URL + "/qr-code/" + value + ".svg?size=" + size}
      alt={value}
    />
  );
};
QRCodeImage.propTypes = {
  value: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
};

export default QRCodeImage;
