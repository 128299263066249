import React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import {
  ReferenceField,
  Filter,
  TextInput,
  Button,
  BooleanInput
} from "react-admin";
import PropTypes from "prop-types";
import { pieceSetDone } from "../apiClient";
import { useDispatch } from "react-redux";
import { refreshView } from "ra-core";
import { lightBlue } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";

const PieceFilter = props => (
  <Filter {...props}>
    <TextInput label="WB, Smart, PID" source="q" alwaysOn />
    <BooleanInput label="Voir tous les colis" source="seeAll" alwaysOn />
  </Filter>
);

const ShipmentIdField = ({ source, record = {} }) => {
  const parts = record[source].split("-");
  return <span>{parts[parts.length - 1]}</span>;
};

ShipmentIdField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

const PieceDoneButton = ({ label, record }) => {
  const dispatch = useDispatch();
  async function save() {
    await pieceSetDone(record);
    dispatch(refreshView());
  }

  return record.status === "todo" ? (
    <Button
      label={label}
      onClick={save}
      style={{
        backgroundColor: lightBlue["A700"]
      }}
    >
      <CheckIcon />
    </Button>
  ) : (
    <React.Fragment />
  );
};
PieceDoneButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object
};

const PieceList = props => (
  <ListGuesser
    {...props}
    filters={<PieceFilter />}
    bulkActionButtons={false}
    title="DHL Colis"
  >
    <ReferenceField
      label="Shipment"
      source="shipment"
      reference="shipments"
      link="show"
      sortable={false}
    >
      <ShipmentIdField source={"@id"} />
    </ReferenceField>
    <FieldGuesser source={"smartCode"} />
    <FieldGuesser source={"pid"} />
    <FieldGuesser source={"dropOffDateTime"} />
    <FieldGuesser source={"numRetour"} />
    <PieceDoneButton label={"Traiter"} />
    <FieldGuesser source={"status"} />
  </ListGuesser>
);

export { PieceList };
