import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField, Button, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

function Profile(props) {
  const [plainPassword, setPlainPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  function snackbarClose() {
    setSnackbarOpen(false);
  }

  async function save(event) {
    await props.userService.changePassword(plainPassword);
    setSnackbarMessage("Mot de passe mis à jour");
    setSnackbarOpen(true);
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Profil" />
        <CardContent>
          <Grid container direction={"column"} wrap={"wrap"}>
            <Grid item>
              <Typography variant="body2" component="p">
                Changer votre mot de passe
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                type="password"
                label="Mot de passe"
                value={plainPassword}
                onChange={event => setPlainPassword(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={save}
                disabled={plainPassword.length === 0}
                style={{ marginTop: 16 }}
              >
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={snackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
}

Profile.propTypes = {
  userService: PropTypes.object,
  iri: PropTypes.string
};

export default Profile;
