import React, { useEffect, useState } from "react";
import { Link } from "react-admin";
import { pieceDetailsSearch, pieceSetDone } from "../../apiClient";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import QRCodeImage from "../../components/QRCodeImage";
import Piece from "./piece";
import PieceClickDialog from "./piece_click_dialog";

const useStyles = makeStyles({
  copyToClipboard: {
    cursor: "pointer"
  },
  pieceContainer: {
    textAlign: "center"
  }
});

const Scan = () => {
  const [data, setData] = useState(null);
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [todo, setTodo] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  async function handleDialogClose() {
    setDialogOpen(false);
  }

  async function handleDialogAccept() {
    setDialogOpen(false);
    await save();
  }

  function snackbarClose() {
    setSnackbarOpen(false);
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      const fetchData = async () => {
        await getPieceDetails("9400100012");
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    setTodo(data !== null && data.piece.status !== "done");
  }, [data]);

  async function getPieceDetails(smartCode) {
    try {
      const result = await pieceDetailsSearch(smartCode);
      setData(result.data);
    } catch (e) {
      setData(null);
    }
  }

  function handleClick(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSnackbarMessage(`${text} copié`);
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error("Impossible de copier le text '" + text + ". " + error);
      });

    if (todo) {
      setDialogOpen(true);
    }
  }

  async function save() {
    const piece = data.piece;
    await pieceSetDone(piece);
    await getPieceDetails(piece.smartCode);
  }

  return (
    <Grid
      container
      direction={"column"}
      wrap={"wrap"}
      className={classes.pieceContainer}
    >
      <Grid item xs={12}>
        <TextField
          label="Saisir SMART ou PID"
          variant="filled"
          onChange={event => getPieceDetails(event.target.value)}
          style={{ width: "100%" }}
        />
      </Grid>

      {data !== null && (
        <React.Fragment>
          <Grid item xs={12}>
            <Piece
              piece={data.piece}
              nbPiece={data.nbPiece}
              nbPieceDone={data.nbPieceDone}
              waybill={data.waybill}
              iata={data.iata}
              facility={data.facility}
              handleClick={handleClick}
            />
          </Grid>
          {todo && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: lightBlue["A700"],
                  width: "80%",
                  height: "100px"
                }}
                onClick={save}
              >
                Colis traité
              </Button>
            </Grid>
          )}
          <Grid item style={{ paddingTop: 24 }}>
            <Typography variant="subtitle1" gutterBottom>
              NumRetour:{" "}
              <code
                className={classes.copyToClipboard}
                onClick={() => handleClick(data.piece.numRetour)}
              >
                {data.piece.numRetour}
              </code>
            </Typography>
            <QRCodeImage
              value={data.piece.numRetour}
              size={150}
              onClick={() => handleClick(data.piece.numRetour)}
            />
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12}>
        {data === null && (
          <React.Fragment>
            <Typography variant="h2" style={{ padding: 46 }}>
              Aucun colis trouvé.
            </Typography>
            <Typography variant="subtitle" style={{ padding: 46 }}>
              Accèder à la <Link to="pieces">liste des colis</Link>.
            </Typography>
          </React.Fragment>
        )}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={snackbarClose}
        message={snackbarMessage}
      />
      <PieceClickDialog
        open={dialogOpen}
        handleAccept={handleDialogAccept}
        handleClose={handleDialogClose}
      />
    </Grid>
  );
};

export default Scan;
