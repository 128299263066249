import axios from "axios";
import { getRawToken } from "./app/userService";
import { dataProvider } from "./app/dataProvider";

const entrypoint = process.env.API_URL;

function pieceDetailsSearch(smartCode) {
  const token = getRawToken();
  return axios.get(entrypoint + "/pieces/" + smartCode + "/details", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

async function pieceSetDone(piece) {
  await dataProvider.update("pieces", {
    id: piece["@id"],
    data: { status: { value: "done" } }
  });
}

export { pieceDetailsSearch, pieceSetDone };
