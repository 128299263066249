import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import {
  SingleFieldList,
  ChipField,
  EmailField,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  PasswordInput,
  BooleanField,
  BooleanInput,
  TextInput,
  Filter,
  email
} from "react-admin";
import { ListGuesser, CreateGuesser, EditGuesser } from "@api-platform/admin";
import { get } from "../app/client";
import { Grid, useTheme } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
const validateEmail = email();

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Recherche" source="email" alwaysOn />
  </Filter>
);

const UserList = props => (
  <ListGuesser {...props} filters={<ListFilter />} title="Utilisateurs">
    <EmailField source="email" />
    <ReferenceArrayField
      label="Rôles"
      source="rolesCollection"
      reference="roles"
      sortable={false}
    >
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
    <BooleanField source="active" label="Activé ?" />
  </ListGuesser>
);

const UserCreate = props => (
  <CreateGuesser {...props}>
    <TextInput source="email" type="email" validate={validateEmail} />
    <PasswordInput source="plainPassword" />
    <ReferenceArrayInput
      label="Roles"
      source="rolesCollection"
      reference="roles"
    >
      <AutocompleteArrayInput />
    </ReferenceArrayInput>
    <BooleanInput source="active" />
  </CreateGuesser>
);

function UserEdit(props) {
  const [iatas, setIatas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await get("iatas");
      setIatas(response["hydra:member"]);
    }
    fetchData();
  }, []);

  return (
    <EditGuesser {...props}>
      <TextInput source="email" type="email" validate={validateEmail} />
      <PasswordInput source="plainPassword" />
      <ReferenceArrayInput
        label="Roles"
        source="rolesCollection"
        reference="roles"
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <BooleanInput source="active" />

      <Iatas iatas={iatas} />
    </EditGuesser>
  );
}

function Iatas(props) {
  const { iatas } = props;
  const form = useForm();

  useEffect(() => {
    let formValues = form.getState().values.facilities;
    if (formValues.length === 0) {
      formValues = {};
    }
    for (const iata of iatas) {
      if (formValues[iata.code] === undefined) {
        formValues[iata.code] = {};
      }
    }

    form.change("facilities", JSON.parse(JSON.stringify(formValues)));
  }, [iatas, form]);

  return (
    <Grid container>
      {iatas.map((iata, index) => (
        <Iata key={iata.code} iata={iata} index={index} />
      ))}
    </Grid>
  );
}

Iatas.propTypes = {
  iatas: PropTypes.array
};

function Iata(props) {
  const { iata, index } = props;
  const form = useForm();
  const theme = useTheme();

  function selectCode(code, value) {
    const state = form.getState();
    const facilities = state.values.facilities ?? {};

    if (facilities[code] === undefined) {
      facilities[code] = {};
    }

    for (const facility of iata.facilities) {
      facilities[code][facility.code] = value;
    }

    form.change("facilities", JSON.parse(JSON.stringify(facilities)));
  }

  return (
    <Grid
      item
      style={{
        width: 150,
        background:
          index % 2 === 0 ? theme.palette.grey[300] : theme.palette.grey[100],
        marginRight: 24,
        marginBottom: 24,
        padding: 16
      }}
    >
      <Grid container justify={"space-evenly"} alignItems={"center"}>
        <span>{iata.code}</span>
        <span
          onClick={() => {
            selectCode(iata.code, true);
          }}
        >
          <Check />
        </span>
        <span
          onClick={() => {
            selectCode(iata.code, false);
          }}
        >
          <Close />
        </span>
      </Grid>
      {iata.facilities.map(facility => (
        <Facility key={facility.code} facility={facility} iata={iata} />
      ))}
    </Grid>
  );
}

Iata.propTypes = {
  iata: PropTypes.object,
  index: PropTypes.number
};
function Facility(props) {
  const { iata, facility } = props;

  return (
    <BooleanInput
      source={"facilities[" + iata.code + "][" + facility.code + "]"}
      label={facility.code}
    />
  );
}

Facility.propTypes = {
  facility: PropTypes.object,
  iata: PropTypes.object
};

export { UserCreate, UserList, UserEdit };
